.filter-dropdown{
  padding: pxToRem(5) pxToRem(10) pxToRem(5) pxToRem(12);
  font-size: pxToRem(14);
  width: pxToRem(276);

  &--label {
    display: flex;
    width: pxToRem(230);
    justify-content: space-between;
  }

  &--item {
    transition: 100ms;
    padding: pxToRem(5) pxToRem(0);
    font-weight: 400;
    font-size: pxToRem(13);
    cursor: pointer;

    &.active {
      font-weight: 600;
    }
  }

  &--menu {
    margin-top: pxToRem(15);
  }
}

.dropdown-menu {
  &.show__margin {
    margin-top: 10px;
    inset: 0 auto auto -20px !important;
  }
}

.dnd-header-lessons {
  color: #74788D;
  border-color: $table-border-color;
  background-color: rgba(85, 110, 230, 0.04);
  height: 44px;
  align-content: center;

  display: grid;
  grid-row: 1;
  grid-template-columns: minmax(40px, 48px ) minmax(50px, 68px) minmax(100px,140px) minmax(200px, 399px) minmax(200px,auto) 78px;
}

.dnd-tr-lesson {
  align-items: center;
  height: 60px;
  display: grid;
  grid-row: 1;
  grid-template-columns: minmax(40px, 48px ) minmax(50px, 68px) minmax(100px,140px) minmax(200px, 399px) minmax(200px, auto) 78px;

  border: 1px solid #EFF2F7;
  border-radius: 4px;

  &-custom {
    height: 60px;
  }
}

.lessons-page-header {
  font-weight: 600;
  font-size: pxToRem(16);
  line-height: pxToRem(24);
  padding-left: pxToRem(12);
  text-transform: uppercase;
}

.lesson-list {
  width: calc(100% + 10px);
  margin-right: 6px;
  padding-right: 4px;

  &-scrollbar::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  &-scrollbar::-webkit-scrollbar-track {
    border-radius: 10px;
    background: rgba(0,0,0,0.1);
  }
  &-scrollbar::-webkit-scrollbar-thumb{
    border-radius: 10px;
    background: rgba(0,0,0,0.2);
  }
  &-scrollbar::-webkit-scrollbar-thumb:hover{
    background: rgba(0,0,0,0.4);
  }
  &-scrollbar::-webkit-scrollbar-thumb:active{
    background: rgba(0,0,0,.9);
  }
}

.nav-item-border {
  border-bottom: 2px solid #f6f6f6 !important;
}

.disable-border-bottom {
  border: 0 !important;
}

.no-lessons-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  &-text {
    font-size: 14px;
    font-weight: 500;
  }
}