.content-details {
  &--tabs {
    height: pxToRem(65);
    padding: pxToRem(16) pxToRem(18) pxToRem(20) pxToRem(20);

    &__tab {
      max-width: 180px;

      &__span {
        font-weight: normal;
      }
    }
  }
}
