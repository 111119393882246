// 
// Content.scss
//

.create-lesson-btn{
  width: 100px;
  height: 36px;
}

.create-lesson-btn-submit{
  width: 140px;
  height: 36px;
}

.lessons-table table{
  th,td{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}