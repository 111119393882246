.quill{
  height: max-content;
}

.quill .ql-container{
  min-height: 135px;
}
.ql-toolbar.ql-snow{
  background-color: #EFF2F6;
}
.ql-snow.ql-toolbar button, .ql-snow .ql-toolbar button{
  width: max-content;
}

.ql-toolbar.ql-snow .ql-formats:not(.ql-formats:first-of-type){
  padding-left: 15px;
}
.ql-toolbar.ql-snow .ql-formats{
  margin: 0;
  border-right: 1px solid #CED4DA;
  padding-right: 15px;
}
.ql-snow .ql-picker.ql-header .ql-picker-item:before, .ql-snow .ql-picker.ql-header .ql-picker-label:before {
  content: "Paragraph";
}
.ql-editor a[href^="-"]:after {
  content: attr(href);
  font-size: 10px
}

.ql-editor a[href^="-"] {
  color: #495057;
  text-decoration:underline dotted;
}

.ql-snow .ql-tooltip::before {
  content: 'Explanation';
}

.ql-snow .ql-tooltip {
  position: static;
  max-width: max-content;
}

.ql-snow .ql-tooltip[data-mode=link]::before{
  content: 'Enter explanation started with -';
}

.ql-snow{
  overflow: visible;
}