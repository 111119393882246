.dnd-header {
  color: #74788D;
  border-color: $table-border-color;
  background-color: rgba(85, 110, 230, 0.04);
  height: 44px;
  align-content: center;

  display: grid;
  grid-row: 1;
  grid-template-columns: minmax(40px, 48px ) minmax(50px, 68px) minmax(100px,140px) minmax(200px, 399px) minmax(200px,409px) 78px;
}

.dnd-tr {
  align-items: center;
  height: 44px;
  display: grid;
  grid-row: 1;
  grid-template-columns: minmax(40px, 48px ) minmax(50px, 68px) minmax(100px,140px) minmax(200px, 399px) minmax(200px, 650px) 78px;

  border: 1px solid #EFF2F7;
  border-radius: 4px;

  &-custom {
    height: 60px;
  }
}

.sortable {
  box-shadow: 0 10px 20px rgba(18, 38, 63, 0.0313726);
  border-radius: 4px;
}