.location-input {
  position: relative;
}

.location-dropdown {
  position: absolute;
  width: 100%;
  top: 65px;
  left: 0;
  z-index: 10;
  background: #fff;
}
