.image-dropzone{
  width: 150px;
  height: 150px;
  position: relative;
}

.image-dropzone-actions{
  position: absolute;
  top:10px;
  right: 10px;
  display: flex;
  }


.bg-image-dropzone{
  background-color: rgba(52, 58, 64, 0.5);
 }

.uploaded-image-dropzone{
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.file-dropzone{
  height: 168px;}