// 
// _table.scss
// 

.search-bar {
  background-color: $topbar-search-bg;
  border: 0;
  width: 270px;
  border-radius: 30px;
  height: 38px;
  padding: 8px 20px 8px 36px;
}

.search-bar-white {
  background-color: $sidebar-bg;
  border: 1px solid #CED4DA;
  width: 270px;
}

.search-bar:focus {
  background-color: $topbar-search-bg;
  border: 0;
}
.search-bar:focus-visible {
 outline: 0;
}
.table {
  color: $gray-800;

  th {
    font-weight: $font-weight-bold;
  }

  .table-light {
    color: $gray-800;
    border-color: $table-border-color;
    background-color: $table-head-bg;
  }

  .status {
    border-radius: 4px;
    font-size: 10px;
    font-weight: 500;
    line-height: 14px;
    padding: 0 4px;
    max-width: max-content;
  }

  .status-created {
    background: rgba(52, 195, 143, 0.180392);;
    color: $green;
  }

  .status-logged-in {
    background: rgba(85, 110, 230, 0.180392);;
    color: $blue;
  }

  .status-active {
    background: rgba(80, 165, 241, 0.180392);
    color: $cyan;
  }

  .status-unactive {
    background: rgba(241, 180, 76, 0.180392);
    color: $yellow;
  }

  .status-pending {
    background: rgba(85, 110, 230, 0.180392);
    color: $blue;
  }

  .status-canceled-active {
    background: rgba(52, 58, 64, 0.180392);
    color: $gray-800;
  }

}

.table-bordered {
  border: $table-border-width solid $table-border-color;

  th {
    border: $table-border-width solid $table-border-color;
  }

  td {
    border: 0;
  }
}

.dropdown .users-table--filters--dropdown__toggle {
  border-radius: 4px !important;
  padding: 0.47rem 0.7rem !important;
  width: max-content !important;
}

.table-dark > :not(:last-child) > :last-child > * {
  border-bottom-color: $table-dark-border-color;
}

.table-nowrap {
  th, td {
    white-space: nowrap;

  }
}

.table-input {
  border: 1px solid #CED4DA;
  width: 100%;
  height: 36px;
  padding: 8px 12px;
}


