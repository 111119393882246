//
// alert.scss
//

.alert-success {
  background: rgba(52, 195, 143, 0.8);
  border: 2px solid #34C38F;
  color: #fff;
  position: absolute;
  right: 24px;
  top: 80px;
  .icon-check{
    margin-right: 15px;
  }
}

.custom-alert {
  max-width: 450px;
}