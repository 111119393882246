.edit-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.edit-user-btn {
  width: 100px;
  height: 40px;
}

.edit-avatar-wrapper {
  display: flex;
  justify-content: center;
}

.user-info-heading, .user-info-text {
  font-size: 15px;
  font-weight: 500;
}


.user-info-text {
  color: #74788D;

}


